











import {MetaInfo} from 'vue-meta'
import {Component, Vue} from 'vue-property-decorator'
import {PortalTarget} from 'portal-vue'

Component.registerHooks(['metaInfo'])

@Component({
  components: {PortalTarget},
})
export default class App extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: this.$t('app.title') as string,
    }
  }

  created() {
    this.$auth.populateToken()
    this.$walletAdapter.init()
  }
}
