import {rpc, wallet} from '@cityofzion/neon-core'
import {EnvHelper} from '@/helpers/EnvHelper'
import axios from 'axios'
import {$} from '@/facade'

export class NeoHelper {
  static readonly rpcUrl = EnvHelper.VUE_APP_RPC_URL
  static readonly attemptsToValidateTransaction =
    EnvHelper.VUE_APP_MAX_ATTEMPTS_TO_VALIDATE_TRANSACTION ?? 25

  static async validateTransaction(transactionHash: string): Promise<boolean> {
    const rpcClient = new rpc.RPCClient(this.rpcUrl)
    const maxTries = this.attemptsToValidateTransaction

    let tries = 0

    do {
      tries += 1

      await new Promise(resolve => setTimeout(resolve, 4000))

      try {
        const result = await rpcClient.getApplicationLog(transactionHash)

        const execution = result.executions[0]

        if (!execution || !execution.stack || execution.vmstate !== 'HALT')
          break

        const [stack] = execution.stack

        if (!stack || !stack.value) break

        if (execution.notifications.length <= 0) break

        return true
        // eslint-disable-next-line no-empty
      } catch {}
    } while (tries < maxTries)

    return false
  }

  static async getVoterData() {
    const url = `${EnvHelper.DORA_URL}api/v2/neo3/mainnet/voter/${$.walletAdapter.address}`

    const {data} = await axios.get(url)

    return {
      neoBalance: String(data.balance),
      candidatePublicKey: data.candidatePubkey,
    }
  }

  static getScriptHashFromAddress(address: string) {
    return `0x${new wallet.Account(address).scriptHash}`
  }
}
